.custom-schedule .e-toolbar .e-primary,
.custom-schedule .e-selected-cell,
.custom-schedule .e-appointment .e-header-cells.e-current-day {
  background-color: #fcc2af !important;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text {
  color: #ea5828 !important;
}

.e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #ea5828 !important;
  border: 1px solid #ea5828;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: #363636 !important;
}

.e-appointment {
  background-color: #ea5828 !important;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
  color: #ea5828 !important;
}

/* Update text color for toolbar buttons (Day, Week, Month, Agenda, Today) */
.e-schedule .e-toolbar .e-toolbar-items .e-btn {
  color: #ea5828 !important; /* Change the text color to orange */
  border-color: #ea5828 !important; /* Optional: Change the border color */
}

/* Change text color for the active button */
.e-schedule .e-toolbar .e-toolbar-items .e-btn.e-active {
  color: #0b5828 !important; /* Orange for active button text */
  font-weight: bold !important; /* Make the active button text bold */
  border-bottom: 2px solid #ea5828 !important; /* Optional: Add underline effect */
}

/* Highlight the current day button text */
.e-schedule .e-toolbar .e-today {
  color: #ea5828 !important; /* Orange for "Today" text */
  font-weight: bold !important; /* Optional: Make "Today" button text bold */
}

/* Optional: Add hover effects */
.e-schedule .e-tbar-btn-text .e-btn:hover {
  color: #d94c1f !important; /* Slightly darker orange on hover */
  border-color: #d94c1f !important; /* Match text color for border on hover */
}
