* {
  font-family: "Inter";
}

.rmdp-wrapper {
  width: fit-content;
  border-radius: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  z-index: 999;
  margin-left: 30px;
}

.derecha {
  margin-left: -90px;
}

.rmdp-container {
  width: 50%;
}
