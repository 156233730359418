:root {
  --rmdp-primary-red: #ea5828;
  --rmdp-secondary-red: #ad9087;
  --rmdp-shadow-red: #dedede;
  --rmdp-today-red: #f2aa8d;
  --rmdp-hover-red: #ffc1a4;
  --rmdp-deselect-red: #af4517;
}

* {
  font-family: "Inter";
}

.rmdp-wrapper {
  width: 100% !important;
  margin-left: 0 !important;
}

.rmdp-top-class {
  width: 100%;
}

@media (max-width: 1000px) {
  .rmdp-wrapper {
    width: 100%;
  }
  .rmdp-top-class {
    width: 100%;
  }
}

.orange .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-red);
  box-shadow: 0 0 5px var(--rmdp-secondary-red);
}

.orange .rmdp-panel-body li {
  background-color: white;
  /* box-shadow: 0 0 2px var(--rmdp-secondary-red); */
  box-shadow: none;
  border: 1px solid #ea5828;
  color: #ea5828 !important;
}

div.rmdp-panel.right {
  width: 100%;
}

.orange .b-deselect {
  background-color: #ea5828 !important;
  color: white !important;
}

.orange .rmdp-week-day {
  color: var(--rmdp-primary-red);
  font-weight: 400;
}

.orange .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-red);
}

.orange .rmdp-range-hover {
  background-color: var(--rmdp-shadow-red);
  color: black;
}

.orange .rmdp-range {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}

.orange .rmdp-arrow {
  border: solid var(--rmdp-primary-red);
  border-width: 0 2px 2px 0;
}

.orange .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-secondary-red);
}

.orange .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-red);
}

.orange .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-red);
}

.rmdp-header-values,
.rmdp-panel-header {
  font-weight: 200;
  font-size: 1.2rem;
}

.rmdp-panel-body li {
  border-radius: 0;
}

.rmdp-panel-body li .b-date {
  font-size: 0.8rem;
  font-weight: 300;
  color: #ea5828;
}

.orange .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-red);
}

.orange .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-red);
  box-shadow: 0 0 3px var(--rmdp-shadow-red);
}

.orange .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-red) !important;
}

.orange .b-deselect {
  color: var(--rmdp-deselect-red);
  background-color: white;
}

.orange .rmdp-action-button {
  color: var(--rmdp-primary-red);
}

.orange .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-red);
}

.orange .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-red);
}
